<template>
  <header-app></header-app>
  <router-view/>
  <footer-component></footer-component>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';
import HeaderApp from './components/HeaderApp.vue';

export default {
  name : "App",
  components : {
    FooterComponent,
    HeaderApp
  },
 
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  
  h1, h2, h3, p, body {
    margin:0;
    padding:0;
    font-family: 'Raleway', sans-serif;
  }

  p {
    font-size:15px
  }

  section {
    padding:80px 0;
  }

  .row {
    display: flex;
    width:100%;
  }

  .col-50 {
    width:46%;
    margin:0 2%;
  }

  .box {
    width : 1400px;
    max-width: 96%;
    margin:0 auto;
  }
      //color picker
      .hu-color-picker {
        padding: 10px 0;
        background: $secondaryColor;
        //border-radius: 4px;
        //box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%);
        z-index: 1;
    }

    .color-set {
        display:flex;
    }

    .hue {
        position: relative;
        margin-left: 8px;
        cursor: pointer;
    }

    .color-alpha {
        position: relative;
        margin-left: 8px;
        cursor: pointer;
    }

    .color-type {
        display: flex;
        margin-top: 8px;
        font-size: 12px;
    }

    .color-type .name {
        width: 60px;
        height: 30px;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        background: #252930;
    }

    .color-type .value {
        flex: 1;
        height: 30px;
        min-width: 100px;
        padding: 0 12px;
        border: 0;
        color: #fff;
        background: #2e333a;
        box-sizing: border-box;
    }

    @media  screen and (max-width:1024px) {
      .row {
        flex-direction: column;
      }

      .col-50 {
        width:90%;
        margin: 0 5%;
      }

      section {
        padding:40px 0;
      }
    }
</style>
