<template>
    <footer>
        <img src="./../assets/logo-footer.png" alt="logo" class="logo-footer" />
        <nav class="menu-footer">
            <router-link to="/#top" class="menu-link-footer">Accueil</router-link>
            <router-link to="/#project" class="menu-link-footer">Comment ça marche ?</router-link>
            <router-link to="/#templates"  class="menu-link-footer">Templates</router-link>
            <router-link to="/#prices"  class="menu-link-footer">Tarifs</router-link>            
            <router-link to="/#order"  class="menu-link-footer">Commander</router-link>
            <router-link to="/#about"  class="menu-link-footer">Contactez-nous !</router-link>
        </nav>
        <div class="social-network-container">
            <a href="https://www.linkedin.com/company/synthes3d/" target="_blank" class="social-network-icon linkedin"></a>
            <a href="https://www.instagram.com/synthes3d/" target="_blank" class="social-network-icon instagram"></a>
            <a href="https://www.youtube.com/@Synthes3d" target="_blank" class="social-network-icon facebook"></a>
        </div>
        <div class="copyright">
            <p>
                <!-- the 3d animations factory ©{{currentYear}} – <router-link :to="{name : 'legalNotices'}">Mentions légales</router-link> -->
                the 3d animations factory ©{{currentYear}} – <a href="https://www.synthes3d.com/fr/mentions-legales/" target="_blank">Mentions légales</a>
            </p>
            <p class="resp">
                the 3d animations factory ©{{currentYear}}<br />
                <a href="https://www.synthes3d.com/fr/mentions-legales/" target="_blank">Mentions légales</a>
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name : "FooterComponenent",
    data() {
        return {
            currentYear : new Date().getFullYear()
        }
    }
}
</script>

<style scoped lang="scss">
    footer {
        padding:40px 0 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background:$mainColor;
    }

    .logo-footer {
        margin-bottom: 40px;
        max-width: 90%;
    }

    nav {
        display: flex;
        justify-content: center;
    }

    .social-network-container{
        margin:40px auto;
        display: flex;
        justify-content: center;
    }

    .social-network-icon {
        height:30px;
        width:30px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin:0 10px;
        cursor: pointer;
        transition: 0.5s;
    }

    .linkedin {
        background-image: url('./../assets/icon-linkedin.png');
    }

    .linkedin:hover {
        background-image: url('./../assets/icon-linkedin-o.png');
    }

    .twitter {
        background-image: url('./../assets/icon-twitter.png');
    }

    .twitter:hover {
        background-image: url('./../assets/icon-twitter-o.png');
    }

    .instagram {
        background-image: url('./../assets/icon-instagram.png');
    }

    .instagram:hover {
        background-image: url('./../assets/icon-instagram-o.png');
    }

    .facebook {
        background-image: url('./../assets/icon-fb.png');
    }

    .facebook:hover {
        background-image: url('./../assets/icon-fb-o.png');
    }

    .menu-link-footer {
        color:$secondaryColor;
        text-transform: uppercase;
        font-weight: 300;
        font-size:18px;
        margin:0 10px;
        text-decoration: none;
        transition: 0.5s;
    }

    .menu-link-footer:hover, .copyright a:hover {
        color:$focusColor;
    }

    .copyright p {
        text-transform: uppercase;
        text-align: center;
        color:$secondaryColor;
    }

    .copyright a {
        color:$secondaryColor;
        text-decoration: none;
        transition: 0.5s;
    }
    .resp {
        display: none;
    }

    @media screen and (max-width:1024px) {

        .copyright p {
            display: none;
        }
        .copyright p.resp {
            display: block;
            line-height: 1.5em;
        }

        .menu-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .menu-link-footer {
            margin: 5px 0;;
        }
    }


</style>