import { createStore } from 'vuex'

export default createStore({
  state: {
    TEMPLATE_SELECTED : null,
    // URL : "http://localhost:8081",
    menu_open : false,
    // API : "http://localhost:8080/api",
    URL : "https://3d-animation-factory.vercel.app/",
    API : "https://3-d-animation-factory-api.vercel.app/api"
  },
  getters: {
  },
  mutations: {
    setTemplates(state, data) {
      state.TEMPLATES = data;
    },
    setTemplateSelected(state, data) {
      state.TEMPLATE_SELECTED = data;
    },
    removeTemplate(state) {
      state.TEMPLATE_SELECTED = null;
    },
    closeMenu(state) {
      state.menu_open = false;
    }
  },
  actions: {
  },
  modules: {
  }
})
