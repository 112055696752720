<template>
    <header :class="type">
        <div class="box">
            <div class="logo-container">
                <router-link to="/#top">
                    <img src="./../assets/logo-white.png" alt="logo" class="logo" />
                </router-link>
            </div>
            <nav class="menu-container">
                <router-link to="/#project" class="menu-link">Comment ça marche ?</router-link>
                <router-link to="/#templates"  class="menu-link">Templates</router-link>
                <router-link to="/#prices"  class="menu-link">Tarifs</router-link>
                <router-link to="/#options"  class="menu-link">Options</router-link>                
                <router-link to="/#order"  class="menu-link">Commander</router-link>
                <router-link to="/#about"  class="menu-link">Contactez-nous !</router-link>
            </nav>
            <!-- MENU RESP -->
            <div class="menu-header-resp">
                    <div class="burger-nav" @click="$store.state.menu_open = !$store.state.menu_open" :class="$store.state.menu_open ? 'open' : ''" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <nav class="menu-header-resp-content" v-if="$store.state.menu_open">
                        <router-link to="/#project" class="menu-item">Comment ça marche ?</router-link>
                        <router-link to="/#templates"  class="menu-item">Templates</router-link>
                        <router-link to="/#prices"  class="menu-item">Tarifs</router-link>
                        <router-link to="/#options"  class="menu-item">Options</router-link>                       
                        <router-link to="/#order"  class="menu-item">Commander</router-link>
                        <router-link to="/#about"  class="menu-item">Contactez-nous !</router-link>
                    </nav>
                </div> 
        </div>
    </header>
</template>

<script>
export default {
    name : "HeaderApp",
    data() {
        return {
            type : null
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        this.getType();
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
         handleScroll() {
             this.getType();
        },
        getType() {
            window.pageYOffset < 100 ? this.type = null : this.type = "scroll";
        }
    }
}
</script>

<style scoped lang="scss">
    header {
        position:fixed;
        top:0;
        left:0;
        right:0;
        display: flex;
        justify-content: center;
        z-index: 99;
        background:$secondaryColor;
    }

    header.scroll {
        box-shadow: $boxShadow;
    }

    .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px 0;
    }

    .menu-container {
        display: flex;
    }

    .menu-link {
        font-size:15px;
        color:#fff;
        text-decoration: none;
        margin:0 20px;
        letter-spacing: 1px;
        transition: 0.5s;
        font-weight:700
    }

    .menu-link:hover {
        color:$focusColor;
    }

     /* MENU RESP */

     .menu-header-resp {
        display: none;
     }

     .burger-nav {
        width: 20px;
        height: 20px;
        position: relative;
        margin-right:20px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }

    .burger-nav span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #fff;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    .burger-nav span:nth-child(1) {
        top: 0px;
    }

    .burger-nav span:nth-child(2),
    .burger-nav span:nth-child(3) {
        top: 8px;
    }

    .burger-nav span:nth-child(4) {
        top: 16px;
    }

    .burger-nav.open span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    .burger-nav.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .burger-nav.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-nav.open span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    .menu-header-resp-content {
        position: fixed;
        top: 77px;
        left:0;
        right: 0;
        background: $mainColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:0;
        z-index:99;
    }

    .menu-header-resp-content .menu-item {
        width:100%;
        border: none;
        padding:15px 0;
        text-decoration: none;
        color:$secondaryColor;
        background:$mainColor;
        font-size:15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height:auto;  
        border:none;   
        font-weight: 700;
    }

    // .menu-header-resp-content .menu-item:hover, .menu-header-resp-content .menu-item.menu-item.router-link-exact-active {
    //     background:$mainColor;
    //     color:#fff;
    // }

    @media screen and (max-width:1200px) {
        .menu-link {
            margin: 0 8px;
        }
    }

    @media screen and (max-width:1024px) {
        .menu-container {
            display: none;
        }

        .menu-header-resp {
            display: block;
        }


    }
</style>