import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/template/:id',
    name: 'template',
    component: () => import('../views/TemplateView.vue')
  },
  {
    path: '/mentions-legales',
    name: 'legalNotices',
    component: () => import('../views/LegalNoticesView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {

      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0 }
  }
})


export default router
